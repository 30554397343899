import logo from './logo.svg';
import './App.css';
import QRCode from "qrcode.react";
import { useState } from 'react';

function App() {
  
  const [qrValue, setQRValue] = useState('');
  const [folderValue, setFolderValue] = useState('');




  function download() {
    const canvas = document.querySelector('.HpQrcode > canvas');
    const link = document.createElement("a");
    link.href = canvas.toDataURL();
    link.download = `${folderValue}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <>
      <div className='HpQrcode' style={{ flexDirection: 'column', display: 'flex', gap: 10, placeContent: 'center', justifyContent: 'center', placeItems: 'center' }}>
        <QRCode id="QRCode-svg" value={`http://${qrValue}:9999`} />

        <label>Server IP</label>
        <input
          type="text"
          id="message"
          name="message"
          onChange={(e) => setQRValue(e.target.value)}
          value={qrValue}

        />

        <label>Dateiname</label>
        <input
          type="text"
          id="message"
          name="message"
          onChange={(e) => setFolderValue(e.target.value)}
          value={folderValue}

        />

        <button
          className="btn-success border-0 rounded w-50 py-2 text-center my-2 d-flex align-items-center justify-content-center align-self-center"
          onClick={download}
        >
          Herunterladen
        </button>
      </div>
    </>
  )
}

export default App;
